/* eslint-disable @typescript-eslint/naming-convention */
import React, {useEffect, useLayoutEffect, useState} from 'react';
import cx from 'classnames';
import {LeftArrow} from '../../../icons/dist';
import s from '../Navigation.scss';
import {NavigationDataHooks} from '../Navigation';

/* istanbul ignore next: todo: test */
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

interface ShortBreadcrumbsProps {
  pageName: string;
  href: string;
  isRTL: boolean;
  hideNavigationUrls: boolean;
  t: (string: any, values?: object, fallback?: string) => string;
}

export const ShortBreadcrumbs = (props: ShortBreadcrumbsProps): JSX.Element => {
  const [isAfterHydration, setAfterHydration] = useState(false);

  useIsomorphicLayoutEffect(() => {
    setAfterHydration(true);
  }, []);

  const {pageName, href, isRTL, hideNavigationUrls, t} = props;
  const iconClass = cx({[s.shortBreadcrumbsRTL]: isRTL});
  return (
    <React.Fragment key={isAfterHydration ? 1 : 0}>
      <a
        href={hideNavigationUrls ? undefined : href}
        data-hook={NavigationDataHooks.ShortBreadcrumbs}
        className={s.shortBreadcrumbs}>
        <LeftArrow className={iconClass} />
        <span>{t('BREADCRUMBS_BACK_TO_PAGE_LINK', {pageName})}</span>
      </a>
    </React.Fragment>
  );
};
