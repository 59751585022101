import * as React from 'react';
import {withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './EmptyState.scss';
import {Navigation} from '../../Navigation/Navigation';
import {Cell} from '../Cell/Cell';

export const EmptyState: React.FC<LayoutComponentProps & IProvidedTranslationProps> = withGlobalProps(
  withTranslations('globals.texts')((props: LayoutComponentProps & IProvidedTranslationProps) => {
    const {t} = props;
    const homepage = props.globals.pagePath[0];

    return (
      <div className={s.container}>
        <Cell className={s.navigation}>
          <Navigation renderPagination={false} />
        </Cell>
        <Cell className={s.headline} data-hook="empty-state-headline">
          {t('productPage.error.message')}
        </Cell>
        <Cell className={s.continue}>
          <a data-hook="empty-state-continue-shopping" href={homepage?.url}>
            {t('productPage.error.CTA')}
          </a>
        </Cell>
      </div>
    );
  })
);
