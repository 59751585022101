import * as React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import s from './CloseQuickView.scss';
import {CloseWithBackground} from '../../../../icons/dist';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {compose} from '../../../../../commons/utils';

export const enum CloseQuickViewHooks {
  CTA = 'close-quick-view',
}

export const CloseQuickView = compose(
  withTranslations('globals.texts'),
  withGlobalProps
)(({t, globals: {closeWixModal}}: ProvidedGlobalProps & IProvidedTranslationProps) => {
  return (
    <button
      type="button"
      data-hook={CloseQuickViewHooks.CTA}
      aria-label={t('productPage.sr.quickView.close.button')}
      className={s.root}
      onClick={closeWixModal}>
      <CloseWithBackground />
    </button>
  );
});
