import React from 'react';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import s from './ProductPageTopSlot.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {ProductPageSlotIds} from '../../constants';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

function ProductPageTopSlotComponent({globals}: ProvidedGlobalProps) {
  const isPluginInstalled = !!globals.slots?.[ProductPageSlotIds.ProductPageTop];
  return (
    <Cell className={isPluginInstalled ? s.spacing : /*istanbul ignore next */ undefined}>
      <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageTop} />
    </Cell>
  );
}

export const ProductPageTopSlot = withGlobalProps(ProductPageTopSlotComponent);
